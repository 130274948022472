<template>
   <section class="footer" >
      <div class="footer-copyright-center">
        Natural Phone 2023 todos los derechos reservados | terminos y condiciones | politicas de privacidad
      </div>
    </section>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.footer {
  width: 100%;
  justify-content: center;
  align-items: center;
/*  f*/

}

.footer-copyright-center{
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
